import React from 'react'
import Footer from '../components/Footer'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
   getSkroutzOrder,
   postSkroutzAccept,
   getInvoiceState,
   updatePayOnDelivery,
} from '../features/skroutz/skroutzSlice'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Spinner from '../components/Spinner'
import { ReactComponent as Note } from '../assets/icons/note.svg'
import { ReactComponent as Euro } from '../assets/icons/euro.svg'
import { ReactComponent as Success } from '../assets/icons/check.svg'
import SkroutzLogo from '../assets/icons/skroutz-logo.png'
import BackButton from '../components/BackButton'
import SkroutzOrderInvoice from '../components/SkroutzOrderInvoice'
import { twoDecimals } from '../utils/format'
import { courierUrl } from '../utils/courierUrl'
import { RxExternalLink } from 'react-icons/rx'
import AerialLogo from '../assets/icons/aerial.png'

function SkroutzOrder() {
   const { orderId } = useParams()
   const [pickupLocation, setPickupLocation] = useState(1)
   const [pickupTime, setPickupTime] = useState(1)

   const [notFound, setNotFound] = useState(false)

   const { singleOrder, isLoadingSkroutz, invoiceState, postInvoiceLoading, payOnDeliverLoading } = useSelector(
      (state) => state.skroutz
   )

   const { invoice_mark, printedInvoice, payOnDelivery, aerialOrderId } = invoiceState

   const dispatch = useDispatch()
   const navigate = useNavigate()

   useEffect(() => {
      dispatch(getSkroutzOrder(orderId))
         .unwrap()
         .then(() => {})
         .catch(() => {
            setNotFound(true)
         })

      dispatch(getInvoiceState(orderId))
   }, [orderId])

   const orderState = (state) => {
      switch (state) {
         case 'open':
            return <span className='skroutz-order-state open'>Νέα</span>
         case 'accepted':
            return <span className='skroutz-order-state accepted'>Προς αποστολή</span>
         case 'dispatched':
            return <span className='skroutz-order-state dispatched'>Παραδόθηκε στην courier</span>
         case 'rejected':
            return <span className='skroutz-order-state rejected'>Απορρίφθηκε</span>
         case 'cancelled':
            return <span className='skroutz-order-state cancelled'>Ακυρώθηκε</span>
         case 'expired':
            return <span className='skroutz-order-state expired'>Έχει Λήξει</span>
         case 'delivered':
            return <span className='skroutz-order-state delivered'>Ολοκληρώθηκε</span>
         case 'partially_returned':
            return <span className='skroutz-order-state partially_returned'>Επιστράφηκε μερικώς</span>
         case 'returned':
            return <span className='skroutz-order-state returned'>Έχει Επιστραφεί</span>
         case 'for_return':
            return <span className='skroutz-order-state for_return'>Προς επιστροφή</span>
         default:
            return null
      }
   }

   // Accept an order
   const changeLocation = (e) => setPickupLocation(e.target.value)
   const changeTime = (e) => setPickupTime(e.target.value)

   const submitAccept = (e) => {
      e.preventDefault()

      const number_of_parcel = 1
      const pickup_location = pickupLocation
      const pickup_window = pickupTime

      if (pickupLocation !== 1 && pickupTime !== 1) {
         const acceptance = { number_of_parcel, pickup_location, pickup_window }
         //console.log(acceptance)

         dispatch(postSkroutzAccept({ query: orderId, data: acceptance }))
            .unwrap()
            .then(() => {
               toast.success('Η παραγγελία έγινε αποδεκτή')
            })
            .catch(toast.error)
      }
   }

   const updatePayOnDeliveryTrue = (e) => {
      //e.preventDefault();

      dispatch(updatePayOnDelivery({ id: orderId, state: true }))
         .unwrap()
         .then(() => {
            toast.success('Η παραγγελία καταχωρήθηκε ως Αντικαταβολή')
         })
         .catch(toast.error)
   }

   const updatePayOnDeliveryFalse = (e) => {
      //e.preventDefault();

      dispatch(updatePayOnDelivery({ id: orderId, state: false }))
         .unwrap()
         .then(() => {
            toast.success('Η παραγγελία αφαιρέθηκε από Αντικαταβολή')
         })
         .catch(toast.error)
   }

   if (notFound) {
      return (
         <div className='page-content top-left-corner'>
            <div className='container'>
               <p className='notfound-page-text'>404 - Η παραγγελία δεν βρέθηκε</p>
            </div>
         </div>
      )
   }

   if (isLoadingSkroutz) {
      return <Spinner />
   }

   return (
      <div className='page-content top-left-corner'>
         <div className='container'>
            <div className='customers-top-btns'>
               <div className='btn-back' onClick={() => navigate(-1)}>
                  Πίσω
               </div>
            </div>
            <h2 className='page-title flex-center'>
               Παραγγελία <div>{singleOrder?.order?.code}</div>{' '}
               <span className='skroutz-order-state'>{orderState(singleOrder?.order?.state)}</span>
            </h2>
            <div className='skroutz-flex-container'>
               <div className='skroutz-left-col'>
                  <div className='skroutz-products-container'>
                     {singleOrder?.order?.line_items?.map((product) => (
                        <div key={product.shop_uid} className='skroutz-single-product-list'>
                           <div className='skroutz-single-product-name-quantity'>
                              <span>{product.product_name}</span>
                              <div className='flex-center'>
                                 <span>{product.quantity}</span> x
                                 <span className='skroutz-item-price'>
                                    {twoDecimals(product.unit_price)} <Euro width='14px' height='14px' />
                                 </span>
                              </div>
                           </div>
                           <div className='skroutz-single-product-uid'>
                              <div className='flex-center'>
                                 <span className='flex-center'>
                                    uid: <div>#{product.shop_uid}</div>
                                 </span>
                                 <a
                                    href={`https://www.aerial.net/shop/product_info.php?products_id=${product?.shop_uid}&language=el`}
                                    target='_blank'
                                    className=''
                                 >
                                    <RxExternalLink size={18} />
                                 </a>
                              </div>
                              {product.return_reason && <span className='color-red'>Προς επιστροφή</span>}
                              <span>
                                 Τελική Τιμή: {product.total_price}
                                 <Euro width='14px' height='14px' />{' '}
                                 {product.price_includes_vat ? 'Με ΦΠΑ' : 'Χωρίς ΦΠΑ'}
                              </span>{' '}
                           </div>
                           {product?.island_vat_discount_applied && (
                              <span className='skroutz-single-product-vat'>
                                 Προσοχή ο πελάτης ζήτησε τιμολόγιο με <b>ΛΙΓΟΤΕΡΟ</b> ΦΠΑ λόγω νησιού
                                 <span>
                                    και ορίζονται σε 17%, 9% και 4% αντίστοιχα στα νησιά Λέρο, Λέσβο, Κω, Σάμο, Χίο
                                 </span>
                              </span>
                           )}
                        </div>
                     ))}
                     {singleOrder?.order?.comments && (
                        <div className='skroutz-single-product-list'>
                           <div className='skroutz-order-note'>
                              <Note width='20px' height='20px' />
                              <span>Σχόλια πελάτη</span>
                           </div>

                           <span className='skroutz-single-product-name-quantity'>
                              <span className='skroutz-note'>'{singleOrder?.order?.comments}'</span>
                           </span>
                        </div>
                     )}
                     {singleOrder?.order?.invoice_details?.vat_exclusion_requested && (
                        <span>
                           Προσοχή ο πελάτης ζήτησε τιμολόγιο <bold>ΧΩΡΊΣ</bold> ΦΠΑ
                        </span>
                     )}
                  </div>
                  <div className='skroutz-client-container'>
                     {/* <div className='skroutz-client-info'>
                        {singleOrder?.order?.customer.first_name}
                        {singleOrder?.order?.customer.last_name}
                     </div> */}
                     {singleOrder?.order?.invoice && (
                        <div className='flex-column-client'>
                           <span className='skroutz-client-title'>Στοιχεία Τιμολογίου</span>
                           <span>Εταιρεία: {singleOrder?.order?.invoice_details?.company}</span>
                           <span>Επάγγελμα: {singleOrder?.order?.invoice_details?.profession}</span>
                           <span>
                              Διεύθυνση: {singleOrder?.order?.invoice_details?.address?.street_name}{' '}
                              {singleOrder?.order?.invoice_details?.address?.street_number}
                           </span>
                           <span>ΤΚ: {singleOrder?.order?.invoice_details?.address?.zip}</span>
                           <span>ΑΦΜ: {singleOrder?.order?.invoice_details?.vat_number}</span>
                           <span>ΔΟΥ: {singleOrder?.order?.invoice_details?.doy}</span>
                        </div>
                     )}
                     <div className='flex-column-client'>
                        <span className='skroutz-client-title'>Στοιχεία Πελάτη</span>
                        <span className='flex-center'>
                           Όνομα:{' '}
                           <div>
                              {singleOrder?.order?.customer?.first_name} {singleOrder?.order?.customer?.last_name}
                           </div>
                        </span>
                        <span>
                           Διεύθυνση: {singleOrder?.order?.customer?.address?.street_name}{' '}
                           {singleOrder?.order?.customer?.address?.street_number}
                        </span>
                        <span>Πόλη: {singleOrder?.order?.customer?.address?.city}</span>
                        <span>Νομός: {singleOrder?.order?.customer?.address?.region}</span>
                        <div className='flex-center'>
                           Τηλέφωνο: <div>{singleOrder?.order?.customer?.mobile}</div>
                        </div>
                        <div>
                           Copy:{' '}
                           <div>
                              {singleOrder?.order?.customer?.first_name} {singleOrder?.order?.customer?.last_name}{' '}
                              {singleOrder?.order?.customer?.mobile}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className='skroutz-right-column'>
                  {aerialOrderId && (
                     <div className='skroutz-site-look'>
                        Προβολή στην{' '}
                        <a
                           className='skroutz-outer-link'
                           href={`https://www.aerial.net/shop/ws-admin/orders.php?page=1&oID=${aerialOrderId}&action=edit`}
                           target='_blank'
                        >
                           Aerial <img src={AerialLogo} alt='' width={22} />
                        </a>
                     </div>
                  )}
                  <div className='skroutz-site-look'>
                     Προβολή στον{' '}
                     <a
                        className='skroutz-outer-link'
                        href={`https://merchants.skroutz.gr/merchants/orders/${singleOrder?.order?.code}`}
                        target='_blank'
                     >
                        Skroutz <img src={SkroutzLogo} alt='' width={22} />
                     </a>
                  </div>
                  <div className='isOrderPayOnDelivery'>
                     <div className='payOnDeliveryTitle'>Αντικαταβολή:</div>
                     <div className='payOnDeliveryByttons'>
                        <button
                           className={!payOnDelivery ? 'isPayOnDel' : ''}
                           value={false}
                           onClick={updatePayOnDeliveryFalse}
                           disabled={payOnDeliverLoading}
                        >
                           Όχι
                        </button>
                        <button
                           className={payOnDelivery ? 'isPayOnDel' : ''}
                           value={true}
                           onClick={updatePayOnDeliveryTrue}
                           disabled={payOnDeliverLoading}
                        >
                           Ναι
                        </button>
                     </div>
                  </div>
                  <div>
                     {singleOrder?.order?.accept_options && (
                        <div>
                           <form onSubmit={submitAccept} className='skroutz-accept-order-container'>
                              <select defaultValue={1} onChange={changeLocation}>
                                 <option value={1}>Επιλογή Τοποθεσίας</option>
                                 {singleOrder?.order?.accept_options.pickup_location?.map((location) => (
                                    <option key={location.id} value={location.id}>
                                       {location.label}
                                    </option>
                                 ))}
                              </select>
                              <select
                                 defaultValue={singleOrder?.order?.accept_options.pickup_window[0].id}
                                 onChange={changeTime}
                              >
                                 <option value={1}>Επιλογή Ώρας</option>
                                 {singleOrder?.order?.accept_options.pickup_window?.map((time) => (
                                    <option key={time.id} value={time.id}>
                                       {time.label}
                                    </option>
                                 ))}
                              </select>
                              <button className='btn-submit-skroutz-order  accept'>
                                 Αποστολή Αποδοχής <Success fill='#fff' width='20px' height='20px' />
                              </button>
                           </form>
                        </div>
                     )}
                  </div>
                  <div className='skroutz-courier'>
                     <span>Μεταφορική: {singleOrder?.order?.courier}</span>
                     <span>
                        Κωδικός Αποστολής: <div>{singleOrder?.order?.courier_tracking_codes}</div>
                     </span>
                     <a
                        href={`${courierUrl(singleOrder?.order?.courier)}${singleOrder?.order?.courier_tracking_codes}`}
                        target='_blank'
                        rel='noreferrer'
                        className='skroutz-order-voucher bg-f3f2f2'
                     >
                        Tracker
                     </a>
                     {/* <span>Παραλήφθηκε από μεταφορική: {singleOrder?.order?.created_at}</span> */}

                     <a
                        className={
                           singleOrder?.order?.courier_voucher
                              ? 'skroutz-order-voucher'
                              : 'skroutz-order-voucher disabled'
                        }
                        href={singleOrder?.order?.courier_voucher}
                        target='_blank'
                        rel='noreferrer'
                     >
                        Voucher
                     </a>
                  </div>
                  <div className='skroutz-courier'>
                     <SkroutzOrderInvoice
                        order={singleOrder.order}
                        printedInvoice={printedInvoice}
                        orderId={orderId}
                        invoice_mark={invoice_mark}
                        postInvoiceLoading={postInvoiceLoading}
                     />
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   )
}

export default SkroutzOrder
